import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Champions from "../components/champions"
import Losers from "../components/losers"

export default function Home({ data }) {
  const champs = data.ccffl.listTeams.items
    .filter(item => item.championshipWins === 1)
    .sort((a, b) => a.season < b.season)
  const losers = data.ccffl.listTeams.items
    .filter(item => item.bsbLosses === 1)
    .sort((a, b) => a.season < b.season)

  return (
    <Layout>
      <h1>Champions</h1>
      <Champions data={champs} />
      <h1>BSB Losers</h1>
      <Losers data={losers} />
      <br />
    </Layout>
  )
}

export const query = graphql`
  query {
    ccffl {
      listTeams(
        filter: {
          or: [{ championshipWins: { ne: 0 } }, { bsbLosses: { ne: 0 } }]
        }
        limit: 10000
      ) {
        items {
          name
          season
          seasonWins
          seasonTies
          seasonPointsFor
          seasonPointsAgainst
          seasonLosses
          bsbLosses
          championshipWins
          ownerName
        }
      }
    }
  }
`
