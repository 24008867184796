import React from "react"
import { Link } from "gatsby"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import roundPoints from "../utils/round-points"

export default function Losers({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Season</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Record</TableCell>
            <TableCell>Points For</TableCell>
            <TableCell>Points Against</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.season}>
              <TableCell>
                <Link to={`/seasons/${row.season}`}>{row.season}</Link>
              </TableCell>
              <TableCell>
                <Link to={`/owners/${row.ownerName.toLowerCase()}`}>
                  {row.ownerName}
                </Link>
              </TableCell>
              <TableCell>
                {row.seasonWins}-{row.seasonLosses}-{row.seasonTies}
              </TableCell>
              <TableCell>{roundPoints(row.seasonPointsFor)}</TableCell>
              <TableCell>{roundPoints(row.seasonPointsAgainst)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
